import Cookies from 'js-cookie';

import showPopup from './modals';
import { DrawSingleCaptcha } from './recaptcha';
import { COOKIE_PRIVACY_NAME } from './general/consent-manager';

const COOKIE_NEWSLETTER_POPUP = 'gs_newsletter_cookie';

export default function () {
    const newsletterSavePopupSettings = window.newsletterSavePopupSettings;
    const pageType = document.body.getAttribute('data-type');
    const allowedPageTypes = ["listing", "mainpage", "detail", "cms"];

    if (!newsletterSavePopupSettings || allowedPageTypes.indexOf(pageType) === -1) return;

    const {
        days,
        delay,
        mainText,
        subText,
        imgUrl,
        newsletterClause,
        recaptchaEnabled,
        dataSitekey
    } = newsletterSavePopupSettings;

    const onCloseNewsletterCookieModal = function () {
        Cookies.set(COOKIE_NEWSLETTER_POPUP, '1', { expires: days });
    };

    const onShowNewsletterCookieModal = function () {
        console.log('Newsletter modal shown');
    };

    const newsletterCookie = Cookies.get(COOKIE_NEWSLETTER_POPUP);
    const privacyCookie = !!Cookies.get(COOKIE_PRIVACY_NAME);
    const newsletterForceShow = window.location.href.includes('newsletter-force-show');

    if (!newsletterForceShow && (!privacyCookie || (privacyCookie && newsletterCookie))) return;

    const logoUrl = document.querySelector('.logo img').getAttribute('src') || "";

    const html = `
        ${imgUrl ? `<div class="img-wrapper"><img src="${imgUrl}" alt="Grafika newslettera" /></div>` : ''}
        <div class="text-wrapper" data-run-validator>
            ${logoUrl ? `<img src="${logoUrl}" alt="Logo sklepu" />` : ''}
            ${mainText ? `<strong class="headline">${mainText}</strong>` : ''}
            ${subText ? `<span class="newsletter-cookie-subtext">${subText}</span>` : ''}
            <div class="newsletter-cookie-input field-wrapper">
                <input type="text" data-validator="required, email" placeholder="Tu wpisz swój adres email..." title="Wpisz swój email i naciśnij przycisk obok pola tekstowego, aby zapisać się do usługi Newsletter." autocomplete="off" maxlength="150" />
            </div>
            <div class="newsletter-cookie-clause-wrapper">
                <div ${recaptchaEnabled ? "data-recaptcha-form" : ''}>
                    <label>
                        <input type="checkbox" />
                        <span></span>
                        ${newsletterClause}
                    </label>

                    <div class="newsletter-clauses-wrapper"></div>

                    ${recaptchaEnabled ? `<div class="captcha-wrapper" data-sitekey="${dataSitekey}"></div>` : ''}

                    <button ${recaptchaEnabled ? `disabled="disabled"` : ''} class="confirm-button active-button" type="submit">Zapisz</button>
                </div>
            </div>
        </div>
    `;

    //client settings indicates, that popup is disabled
    if (days == 0)
        return;

    setTimeout(function () {
        showPopup({
            header: null,
            body: html,
            overloadClass: 'newsletter-cookie-modal',
            showCallback: onShowNewsletterCookieModal,
            closeCallback: onCloseNewsletterCookieModal
        });

        import('./front-validation')
            .then(module => module.default([document.querySelector('.goshop-modal [data-run-validator]')]));

        DrawSingleCaptcha(document.querySelector('.newsletter-cookie-modal .captcha-wrapper'));

    }, newsletterForceShow ? 10 : delay * 1000);
}