import { on } from 'delegated-events';

import showPopup from './modals';
import { DrawSingleCaptcha } from './recaptcha';

export default function () {

    const availabilityTrigger = document.querySelector('.availability-modal-trigger');

    if (!availabilityTrigger)
        return;

    availabilityTrigger.addEventListener('click', function () {
        const scope = availabilityTrigger.closest('[data-scope="addcart"]');

        const requestBody = {

            ProductId: +scope.querySelector('[data-pid]').getAttribute('data-pid'),
            OptionId: +scope.querySelector('[data-optionid]').getAttribute('data-optionid')

        };

        fetch('availability/popup', {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => response.text())
            .then(data => {

                showPopup({
                    header: 'Powiadom o dostępności',
                    body: data,
                    overloadClass: 'availability-modal-wrapper'
                });

                DrawSingleCaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));

            });
    });

    on('click', '#availability-subscribe', function (e) {
        const recaptchaItem = document.querySelector('.goshop-modal .g-recaptcha-response');

        fetch('/api/frontend/availability', {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify({
                email: document.querySelector('.goshop-modal .availability-modal-inner input[type="text"]').value,
                recaptcha: recaptchaItem ? recaptchaItem.value : '',
                optionId: document.querySelector('.goshop-modal input#optionId').value
            }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                window.currentModalInstance.close();
                showPopup({ body: data.message });
                window.lazyLoadInstance.update();
            })
            .catch(error => {
                console.log(error);
                window.currentModalInstance.close();
            });
    });

}