export default function (target, customOffsetTop, customOffsetBottom, scrollFinishCallback) {
    if (!target) return;

    scrollToElement().then(scrollFinishCallback);


    function scrollToElement() {

        return new Promise((resolve) => {
            target.style.scrollMargin = `
        ${((customOffsetTop || customOffsetTop === 0) ? customOffsetTop : 16) + getPageHeaderOffset()}px
        ${((customOffsetBottom || customOffsetBottom === 0) ? customOffsetBottom : 16)}px`;

            target.scrollIntoView({
                behavior: 'smooth',
            });


            const onScroll = () => {
                clearTimeout(scrollTimeout);
                scrollTimeout = setTimeout(() => {
                    window.removeEventListener("scroll", onScroll);
                    resolve();
                }, 50);
            };

            let scrollTimeout;
            window.addEventListener("scroll", onScroll);
        });

    }
}


export function getPageHeaderOffset() {
    const header = document.querySelector(".shop-header");
    if (!header) return 0;

    let headerOffset = header.clientHeight;

    const cs = getComputedStyle(document.documentElement);

    let headerScrollTransform = parseFloat(cs.getPropertyValue('--headerScrollTransform'));
    if (headerScrollTransform) headerOffset += headerScrollTransform;

    let headerHeightChange = parseFloat(cs.getPropertyValue('--headerHeightChange'));
    if (!header.classList.contains("stuck") && headerHeightChange) headerOffset -= headerHeightChange;

    return headerOffset;
}