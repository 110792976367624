import scrollToElement from './scroll-to-element';
import { getPageHeaderOffset } from './scroll-to-element';

let workSection;
let navBar;
let allHeaders;
let scrollingFromClick = false;
let viewportUpdateLines;
let middleUpdateLine;

export default function () {
    workSection = document.querySelector("[data-desc-navigation]");
    if (!workSection) return;

    const moduleEnabled = Number(workSection.dataset.descNavigation);
    allHeaders = document.querySelectorAll('.description-header');
    if (!moduleEnabled || allHeaders.length < 2) return;

    createNavBar();
    initNavBarObserver();
    initNavButtons();
}

function createNavBar() {
    workSection.insertAdjacentHTML('afterbegin', `
        <div class="nav-sentinel"></div>
        <div class="desc-navigation-wrapper">
            <div class="desc-navigation"></div>
        </div>`);

    navBar = workSection.querySelector(".desc-navigation-wrapper");
    navBar.style.top = `${getPageHeaderOffset()}px`;
}
function initNavBarObserver() {
    const sentinel = document.querySelector('.nav-sentinel');

    const navBarObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (!entry.isIntersecting) return navBar.classList.add("sticking");
            
            navBar.classList.remove("sticking");
            setButtonColor(0);

        });
    }, {
        rootMargin: `${-1 * getPageHeaderOffset()}px 0px 0px 0px`,
    });
    navBarObserver.observe(sentinel);
}

function initNavButtons() {
    allHeaders.forEach((header, index) => {

        const navButton = document.createElement("button");
        navButton.innerText = header.innerText;
        navButton.setAttribute("title", `Przewiń do - ${header.innerText}`);
        navBar.querySelector(".desc-navigation").appendChild(navButton);

        navButton.addEventListener("click", () => { buttonClickActions(header, index) });
        initColorChanging(header, index);
    });
}
function buttonClickActions(header, index) {
    scrollingFromClick = true;
    setButtonColor(index, true);
    scrollToElement(header, navBar.clientHeight, '', () => { scrollingFromClick = false; });
}

function initColorChanging(singleHeader, index) {
    window.addEventListener("load", function () {

        let stickyOffset = (-1 * (getPageHeaderOffset() + navBar.clientHeight + singleHeader.clientHeight));

        viewportUpdateLines = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setButtonColor(getLikelyHeaderIndex());
            });
        }, {
            rootMargin: `${stickyOffset}px 0px 0px 0px`,
        });

        middleUpdateLine = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setButtonColor(getLikelyHeaderIndex());
            });
        }, {
            rootMargin: `${-0.5 * window.innerHeight}px 0px ${-0.5 * window.innerHeight}px 0px`,
        });


        viewportUpdateLines.observe(singleHeader);
        middleUpdateLine.observe(singleHeader);
    });
}

function getLikelyHeaderIndex() {
    let stickyOffset = getPageHeaderOffset() + navBar.clientHeight;
    let likelyHeaderIndex;
    let previousSmallest = Infinity;

    allHeaders.forEach((header, index) => {
        const rect = header.getBoundingClientRect();

        const distanceToTop = Math.abs(rect.top - stickyOffset);
        const distanceToBottom = Math.abs(window.innerHeight - rect.bottom - stickyOffset);

        if (distanceToTop > distanceToBottom) return;

        if (distanceToTop < previousSmallest) {
            likelyHeaderIndex = index;
            previousSmallest = distanceToTop;
        }
    });

    return (likelyHeaderIndex ? likelyHeaderIndex : 0);
}
function setButtonColor(indexToColor, indexFromClick) {
    if (scrollingFromClick && !indexFromClick) return;

    let allButtons = navBar.querySelectorAll("button");
    const className = "highlighted";
    allButtons.forEach((button, index) => {

        button.classList.remove(className);
        if (index !== indexToColor) return;

        button.classList.add(className);

        scrollNavBar(button);
    });
}


function scrollNavBar(button) {
    const container = navBar.querySelector(".desc-navigation");

    const containerLeft = container.scrollLeft;
    const containerRight = containerLeft + container.clientWidth;
    const buttonLeft = button.offsetLeft;
    const buttonRight = buttonLeft + button.clientWidth;

    let makeNextVisibleOffset = 48

    if (buttonRight > containerRight) return container.scrollTo({
        left: buttonRight - container.clientWidth + makeNextVisibleOffset,
        behavior: "smooth"
    });

    if (buttonLeft < containerLeft) return container.scrollTo({
        left: buttonLeft - makeNextVisibleOffset,
        behavior: "smooth"
    });
}