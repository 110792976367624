import showPopup from './modals';
import { DrawSingleCaptcha } from './recaptcha';

export default function () {
    const newsletter = document.getElementById('newsletter');

    if (!newsletter)
        return;

    let trigger = newsletter.querySelector('.popup-trigger');

    trigger.addEventListener('click', function () {

        showPopup({
            header: 'Zapis do newslettera',
            body: newsletter.querySelector('.goshop-modal-content').innerHTML,
            overloadClass: 'standard-newsletter'
        });

        DrawSingleCaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
    });

    newsletter.querySelector('input[type="text"]').addEventListener('keyup', function (e) {
        if ((e.key === "Enter" || e.keyCode === 13))
            trigger.click();

    });

}